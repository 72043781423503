// @import url('https://p.typekit.net/p.css?s=1&k=zyp5gzq&ht=tk&f=26034.26053.26054.26055.26056.26057.26058.26059.26060.26061.26062.26063.26064.26065.26067.26068&a=36058052&app=typekit&e=css');
@font-face {
  font-family: 'Acumin Pro';
  src: url('https://use.typekit.net/af/a2c82e/00000000000000003b9acaf4/27/l?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/a2c82e/00000000000000003b9acaf4/27/d?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/a2c82e/00000000000000003b9acaf4/27/a?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n3&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Acumin Pro';
  src: url('../../fonts/AcuminPro-BoldItalic.eot');
  src: url('../../fonts/AcuminPro-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/AcuminPro-BoldItalic.woff2') format('woff2'),
    url('../../fonts/AcuminPro-BoldItalic.woff') format('woff'),
    url('../../fonts/AcuminPro-BoldItalic.ttf') format('truetype'),
    url('../../fonts/AcuminPro-BoldItalic.svg#AcuminPro-BoldItalic')
      format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Acumin Pro';
  src: url('../../fonts/AcuminPro-Regular.eot');
  src: url('../../fonts/AcuminPro-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/AcuminPro-Regular.woff2') format('woff2'),
    url('../../fonts/AcuminPro-Regular.woff') format('woff'),
    url('../../fonts/AcuminPro-Regular.ttf') format('truetype'),
    url('../../fonts/AcuminPro-Regular.svg#AcuminPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Acumin Pro';
  src: url('../../fonts/AcuminPro-Italic.eot');
  src: url('../../fonts/AcuminPro-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/AcuminPro-Italic.woff2') format('woff2'),
    url('../../fonts/AcuminPro-Italic.woff') format('woff'),
    url('../../fonts/AcuminPro-Italic.ttf') format('truetype'),
    url('../../fonts/AcuminPro-Italic.svg#AcuminPro-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Acumin Pro';
  src: url('../../fonts/AcuminPro-Bold.eot');
  src: url('../../fonts/AcuminPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/AcuminPro-Bold.woff2') format('woff2'),
    url('../../fonts/AcuminPro-Bold.woff') format('woff'),
    url('../../fonts/AcuminPro-Bold.ttf') format('truetype'),
    url('../../fonts/AcuminPro-Bold.svg#AcuminPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
