@import '_reset';
@import '_fonts';
@import '_variables';

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
}

body {
  font-family: 'Acumin Pro', 'Roboto', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
}

.btn {
  height: 53px;
  font-size: 15px;
  line-height: 25px;
  font-weight: 600;
  padding: 0 24px;
}

.btn-icon {
  margin-left: 14px;
  display: inline-block;
}

.blue-500 {
  color: $blue-500;
}

.font-48 {
  font-size: 48px;
  line-height: 58px;
}

.btn {
  display: inline-flex;
  align-items: center;
  transition: 0.3s all linear;
  svg {
    path {
      transition: 0.3s all linear;
    }
  }
  &-light {
    color: $blue-500;
    &:hover {
      color: $grey-1;
      svg {
        path {
          fill: $grey-1;
        }
      }
    }
  }

  &-primary {
    color: $white;
    background: linear-gradient(114.03deg, $blue-400 0%, $blue-500 100%);
    border-color: $blue-400;
    svg {
      path {
        fill: $white;
      }
    }
  }
}

@media (min-width: $lg) {
  .container {
    max-width: 1420px;
  }
}
@media (max-width: 767.98px) {
  .font-48 {
    font-size: 32px;
    line-height: 42px;
  }
  .btn {
    height: 40px;
    font-size: 14px;
    line-height: 20px;
    padding: 0 17px;
  }
}

@media (max-width: 476.98px) {
  .font-48 {
    font-size: 20px;
    line-height: 30px;
  }
}
